import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingBottom: 20,
      paddingLeft: 20,
      paddingRight: 20,
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
    },
    name: {
      fontWeight: "bold",
    },
  })
);


export interface CountdownProps {
  countDown: number;
};


export const CountdownComponent: React.FC<CountdownProps> = (
  {countDown,}: CountdownProps) => {

  const getCountdownValues = (countDown) => {
    const hours = Math.floor(countDown/1000/60/60);
    const minutes = Math.floor((countDown % (1000*60*60)) / 1000/60 );
    const seconds = Math.floor((countDown % (1000*60)) / 1000 );
    return {hours: hours, minutes: minutes, seconds: seconds}
  }

  return (

    <Box
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          textAlign: "center"
        }}
    >
        <Box style={{ flex: 1 }}>
        {countDown >= 0 ?
          <>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
          {"Voting Open - "}
          {getCountdownValues(countDown).hours < 10 ? 0 : ""}
            {getCountdownValues(countDown).hours}
             :
          {getCountdownValues(countDown).minutes < 10 ? 0 : ""}
             {getCountdownValues(countDown).minutes}
             :
          {getCountdownValues(countDown).seconds < 10 ? 0 : ""}
             {getCountdownValues(countDown).seconds}
          </Typography>
          </>
          :
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
          VOTING CLOSED
          </Typography>
        }

        </Box>

    </Box>
  );
};
