import React, { useState, useEffect } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import Config from "../../config";
import { useQuery } from "react-query";
import { useSnackbar } from "notistack";
import {TableOverview} from "./TableOverview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 20,
    },
    round: {
      marginBottom: 20,
    },
    tableContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fa897b",
      color: "#ffffff",
      borderRadius: 20,
      padding: 20,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    listContainer: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    legendRow: {
      display: "flex",
      alignItems: "center",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    indicator: {
      height: 14,
      width: 14,
      borderRadius: 7,
      marginRight: 7,
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    spacer: {
      flex: 1,
    },
  })
);

export const LiveEvent = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [realTables, setRealTables] = useState<any>([]);


  const processData = (data: any) => {
    const realTables: any[] = [];

    if(data["error_found"] === true){
        enqueueSnackbar(
          data["error_message"],
          { variant: "error" }
        );
        return realTables;
    };

    return data["think_tank_real_tables"];
  };

  const { isLoading: isGetLoading, data} = useQuery("get-think-tanks", () => {
    const baseURL = Config.getInstance().getCoordinationServiceURL();
    const eventID = Config.getInstance().getEventID();

    const headers: Headers = new Headers();

    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");

    let url = `${baseURL}/api/events/${eventID}/think_tank_seating/`;

    console.log(baseURL);
    console.log(Config.getInstance().getToken());

    headers.append(
      "Authorization",
      `Bearer ${Config.getInstance().getToken()}`
    );

    return fetch(url, {
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => processData(data));
  },
);

  const { isLoading: isEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  useEffect(() => {
    setRealTables(data);
  }, [data]);


  const isLoading = isGetLoading || isEventLoading;


  return (
    <Box className={classes.container}>
      <Box className={classes.controlContainer}>
        <Box className={classes.row} style={{ padding: 20 }}>
          <Box style={{ flex: 1 }}>
            <Typography color="textSecondary">{eventData?.name}</Typography>
          </Box>


          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
          <Box
            style={{
              flex: 1,
            }}
          >
          </Box>
          </Box>
        </Box>
        {isLoading && <LinearProgress color="primary" />}
      </Box>
      {!isGetLoading && realTables?.length > 0 &&
        <TableOverview
           realTables={realTables}
        />
      }

    </Box>
  );
};
