//import React, { useEffect } from "react";
import {
  Box,
  Button,
  createStyles,
//  IconButton,
  makeStyles,
  Theme,
//  TextField,
//  InputAdornment,
  Typography,
//  Divider,
//  Slider,
//  Switch,
//  FormGroup,
//  FormControlLabel
} from "@material-ui/core";
//import CropFreeRoundedIcon from "@material-ui/icons/CropFreeRounded";
//import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useMutation,
         useQuery,
//         useQueryClient
        } from "react-query";
import Config from "../../config";
//import { SettingsJSON } from "../../interfaces";
//import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
//import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexDirection: "column",
      display: "flex",
      overflow: "hidden",
    },
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },

    searchFieldContainer: {
      padding: 20,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    listContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.paper,
    },
    rowContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      paddingTop: 10,
      paddingBottom: 10
    },
    scrollContainer: {
      paddingBottom: 20,
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
/*    sliderContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
    },*/
sliderContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      height: 100,
      width: 300,
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 10,
      borderRadius: 10,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
  })
);



export const DataAnalysis = () => {
  const classes = useStyles();

//  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const deleteEmailTemplates = () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );
      return fetch(
        `${baseURL}/api/events/${eventID}/email_template_generator_delete/`,
        {
          method: "GET",
          headers: headers,
        }
      ).then((res) => res.json());

    };


  const accessRights = localStorage.getItem("access_rights");

    const { isLoading: isRetrieveEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const { isLoading: isRetrieveDataAnalysisLoading, mutate: mutateDataAnalysis } = useMutation(
    (eventName: string) => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      const zip_file = eventName + ".zip";

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );
      //include access rights in header, because returning a blob
      //by default, it is read_only access
      headers.append("Access-Rights", accessRights || 'read_only')

      return fetch(
        `${baseURL}/api/events/${eventID}/data_analysis/`,
        {
          method: "GET",
          headers: headers,
        }
      ).then(res => res.blob())
       .then(blob => {
         const url = window.URL.createObjectURL(blob);
         let a = document.createElement('a');
         a.href = url;
         a.download = zip_file;
         a.click();
       })
     },
    {
      onSuccess: () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );
      return fetch(
        `${baseURL}/api/events/${eventID}/data_analysis_delete/`,
        {
          method: "GET",
          headers: headers,
        }
      ).then((res) => res.json());
      },
    }
   );

  const { isLoading: isRetrieveEmailTemplatesLoading, mutate: mutateEmailTemplates } = useMutation(
    (eventName: string) => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      const zip_file = eventName + "_templates.zip";

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );
      //include access rights in header, because returning a blob
      //by default, it is read_only access
      headers.append("Access-Rights", accessRights || 'read_only')

      return fetch(
        `${baseURL}/api/events/${eventID}/email_template_generator/`,
        {
          method: "GET",
          headers: headers,
        }
      ).then(res => {
                      if(res.headers.get('content-type') === 'zip'){
                        return res.blob();
                      } else {
                        return res.json();
                      };
            })
       .then(data => {

         //download zip file if no error array returned
         if(data.length === undefined){
           //no error found, proceed
           const url = window.URL.createObjectURL(data);
           let a = document.createElement('a');
           a.href = url;
           a.download = zip_file;
           a.click();
         }
         return data;

       })
     },
    {
      onSuccess: (data) => {
        //delete email templates in backend if no errors found
        if(data.length === undefined){
          const emailDeleteRequest = deleteEmailTemplates();
        } else {
          data.map((thisError) => {
            const errorMessage = `${thisError.error_message}; Error code: ${thisError.error_code}`;
            enqueueSnackbar(errorMessage,{ variant: "error" });
          })
        }
      },
      onError: () => {
        const errorMessage="Unhandled error occurred: please contact system administrator."
        enqueueSnackbar(errorMessage,{ variant: "error" });
      }
   }
   );


  const isLoading =
    isRetrieveDataAnalysisLoading ||
    isRetrieveEmailTemplatesLoading ||
    isRetrieveEventLoading;


  return (
    <Box className={classes.container}>
    <Box className={classes.controlContainer}>
      <Box className={classes.row} style={{ padding: 20 }}>
        <Box style={{ flex: 1 }}>
          <Typography color="textSecondary">{eventData?.name}</Typography>
        </Box>
        <Box
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
        </Box>
      </Box>
      {isLoading && <LinearProgress color="primary" />}
    </Box>

    <Box className={classes.listContainer} style={{ padding: 20 }}>
      <Box className={classes.rowContainer}>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          endIcon={<ReplayRoundedIcon />}
          onClick={() => mutateDataAnalysis(eventData?.name)}
        >
          Download Data Analysis
        </Button>
      </Box>

      <Box className={classes.rowContainer}>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          endIcon={<ReplayRoundedIcon />}
          onClick={() => mutateEmailTemplates(eventData?.name)}
        >
          Download Email Templates
        </Button>
      </Box>

    </Box>

  </Box>
  );
};
