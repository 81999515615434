import React from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { DinnerSeatingWithErrorMessage, DinnerParticipantWithErrorMessage } from "../../interfaces";
import { useState } from "react";
import { Popover } from "@material-ui/core";
import { DinnerParticipantDetailView } from "./DinnerParticipantDetailView";
import { TextField } from "@material-ui/core";
import WarningAmber from "../../resources/warning_amber.svg";
import { viridis } from '../../utils/colours';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.paper,
      marginTop: 10,
      marginBottom: 10,
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      paddingLeft: 10,
      paddingTop: 10,
      overflow: "auto",
    },
    seat: {
      padding: 10,
      marginRight: 10,
      marginBottom: 10,
      borderRadius: 10,
      border: `2px solid ${theme.palette.background.default}`,
      minWidth: 120,
      maxWidth: 120,
      cursor: "pointer"
    },
    businessPartnerSeat: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
    notSeatedSeat: {
      border: `2px solid ${theme.palette.error.dark}`,
    },
    notSeatedSeatBusinessPartner: {
      borderTop: `${theme.palette.error.dark}`,
    },
    seatRow: {
      display: "flex",
    },
    errorIndicatorError: {
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #601b17",
      backgroundColor: theme.palette.error.dark,
    },
    errorIndicatorWarning: {
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: `2px solid ${theme.palette.warning.dark}`,
      backgroundColor: theme.palette.warning.dark,
    },
    errorIndicatorInfo: {
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: `2px solid ${theme.palette.info.dark}`,
      backgroundColor: theme.palette.info.dark,
    },
    preferenceContainer: {
      padding: 10,
      borderRadius: 10,
      marginTop: 10,
      backgroundColor: theme.palette.background.default,
      display: "flex",
      alignItems: "center",
    },
    placeholder: {
      border: `2px dashed ${theme.palette.background.default}`,
      padding: 10,
      marginRight: 10,
      marginBottom: 10,
      borderRadius: 10,
      minWidth: 120,
    },
    textPrimaryBP: {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    },
    textSecondaryBP: {
      color: theme.palette.primary.main
    },
    textPrimaryExecutiveLight: {
      fontWeight: 'bold',
      color: theme.palette.text.primary
    },
    textSecondaryExecutiveLight: {
      color: theme.palette.text.secondary
    },
    textPrimaryExecutiveDark: {
      fontWeight: 'bold',
      color: theme.palette.text.primaryDark
    },
    textSecondaryExecutiveDark: {
      color: theme.palette.text.secondaryDark
    }
  })
);

export interface DinnerTableProps {
  accessRights: string,
  dinnerSeatingsWithErrorMessage: DinnerSeatingWithErrorMessage[];
  sendDataToParent: (arg1: string, arg2: number) => void;
  showDetailedInformation: boolean;
  tableWidth?: number;
}

export const DinnerTable: React.FC<DinnerTableProps> = ({
  accessRights,
  dinnerSeatingsWithErrorMessage,
  sendDataToParent,
  showDetailedInformation,
  tableWidth = 2,
}) => {
  const classes = useStyles();

  const seats: any[][] = [];

  const tableLength = dinnerSeatingsWithErrorMessage.reduce(
    (accumulator: any, currentValue: any) => (
      currentValue.seat_id > accumulator ?
         currentValue.seat_id
         :
         accumulator
    ),
      8
    );

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [selectedParticipant, setSelectedParticipant] =
      useState<DinnerParticipantWithErrorMessage | null>(null);

  dinnerSeatingsWithErrorMessage.forEach((dinnerSeating) => {
    const row =
      seats[dinnerSeating.side_of_table] || Array(tableLength).fill(null);

    row[dinnerSeating.seat_id] = dinnerSeating;

    seats[dinnerSeating.side_of_table] = row;
  });

  const showPopover = Boolean(anchorElement);

  const [timer, setTimer] = useState<any>();


  const handleChange = (event: any) => {
    if (event.target.value !== "") {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        sendDataToParent(event.target.id, event.target.value);
      }, 500)
      setTimer(newTimer)
    };
    }

  const selectedSeatNumber = (seats: any) => {
    let seat = null;
    for (seat of seats) {
      let this_seat_arr = seat.filter((seat: any) => seat != null && seat.registration_id === selectedParticipant?.id)
      if (this_seat_arr.length > 0){
        return this_seat_arr[0].seat_number;
      }
    }
    return null
  };

  //colour ranges
  const cMin = 0.;
  const cMax = 4.;

//  console.log(selectedParticipant);
//  console.log('tt', dinnerSeatingsWithErrorMessage);

  return (
    <Box className={classes.container}>
      <Popover
          id={showPopover ? `registration-${selectedParticipant?.id}` : undefined}
          open={showPopover}
          anchorEl={anchorElement}
          onClose={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            setAnchorElement(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
      >
        <DinnerParticipantDetailView
            name={selectedParticipant?.name || ""}
            title={selectedParticipant?.title || ""}
            company={selectedParticipant?.company || ""}
            dietary_needs={selectedParticipant?.dietary_needs}
            important_information={selectedParticipant?.important_information}
            error_messages={selectedParticipant?.related_errors}
        />
        {accessRights === "full" &&
        <Box className={classes.preferenceContainer}>
        <TextField
           id={selectedParticipant?.id || "null"}
           label="Seat Number"
           type="number"
           defaultValue={selectedSeatNumber(seats)}
           InputLabelProps={{
             shrink: true,
           }}
            onChange={handleChange}
        />
        </Box>
      }


      </Popover>
      {seats.map((seatRow, rowIndex) => (
        <Box key={rowIndex} className={classes.seatRow}>
          {seatRow.map((seat, seatIndex) =>
            seat ? (
              <Box
                key={seatIndex}
                className={`${classes.seat} ${
                     !seat.is_seated
                     ? classes.notSeatedSeat
                     : seat.type !== "Executive Registration" ? classes.businessPartnerSeat
                     : ""
                }`}
                style={(seat.type === "Executive Registration" && showDetailedInformation)
                           ? {backgroundColor: `rgb(${viridis(cMin,cMax,seat.weight)[0]},
                                                    ${viridis(cMin,cMax,seat.weight)[1]},
                                                    ${viridis(cMin,cMax,seat.weight)[2]})`}
                           : {}
                         }


                onClick={(event) => {
                  setAnchorElement(event.currentTarget);
                  setSelectedParticipant({
                      id: seat.registration_id,
                      name: seat.registration_name,
                      title: seat.registration_title,
                      company: seat.registration_company,
                      dietary_needs: seat.dietary_needs,
                      important_information: seat.important_information,
                      weight: seat.weight | 0,
                      type: seat.type,
                      related_errors: seat.related_errors
                                          .filter((error_message: any) => error_message["type"] === "DinnerSeatingError")
                                          .map((error_message: any) => (error_message["error_message"])),
                      related_error_severities: []
                  });
                }}
              >
                <Typography color="textPrimary" align="center">
                  <Box className={seat.type === "Executive Registration"
                                   ? (showDetailedInformation && seat.weight > (cMax+cMin)/2.) ? classes.textPrimaryExecutiveDark : classes.textPrimaryExecutiveLight
                                   : classes.textPrimaryBP
                                  }>
                    {seat.seat_number}
                  </Box>
                  <Box className={seat.type === "Executive Registration"
                                   ? (showDetailedInformation && seat.weight > (cMax+cMin)/2.) ? classes.textSecondaryExecutiveDark : classes.textSecondaryExecutiveLight
                                   : classes.textSecondaryBP
                                 }>
                    {seat.registration_name}
                  </Box>
                  <Box className={seat.type === "Executive Registration"
                                   ? (showDetailedInformation && seat.weight > (cMax+cMin)/2.) ? classes.textSecondaryExecutiveDark : classes.textSecondaryExecutiveLight
                                   : classes.textSecondaryBP
                                  }>
                    {seat.registration_company}
                  </Box>
                  <Box className={seat.type === "Executive Registration"
                                   ? (showDetailedInformation && seat.weight > (cMax+cMin)/2.) ? classes.textSecondaryExecutiveDark : classes.textSecondaryExecutiveLight
                                   : classes.textSecondaryBP
                                 }>

                  {seat.related_errors && seat.related_errors.length > 0 &&
                      <img
                          alt="logo"
                          className={classes.errorIndicatorError}
                          src={WarningAmber}
                      />
                }
                  {seat.dietary_needs &&
                      <img
                          alt="logo"
                          className={classes.errorIndicatorWarning}
                          src={WarningAmber}
                      />
                }

                  {seat.important_information &&
                      <img
                          alt="logo"
                          className={classes.errorIndicatorInfo}
                          src={WarningAmber}
                      />
                }
                  </Box>
                </Typography>
              </Box>
            ) : (
              <Box key={seatIndex} className={classes.placeholder}></Box>
            )
          )}
        </Box>
      ))}
    </Box>
  );
};
