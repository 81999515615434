import React from "react";
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popoverContainer: {
            padding: 10,
        },
        thinkTankTableContainer: {
            padding: 10,
            borderRadius: 10,
            marginTop: 10,
            backgroundColor: theme.palette.background.default,
            display: "flex",
            alignItems: "center",
            cursor: "pointer"
        },
    })
);


export const ThinkTankRealTableDetailView: React.FC<{
  thinkTankRealTableId: string,
  thinkTankTableTitle: string,
  sendToParent: (thinkTankRealTableId: string, thinkTankTableId: string) => void
  thinkTankTables: any[]
  }> = ({
    thinkTankRealTableId,
    thinkTankTableTitle,
    sendToParent,
    thinkTankTables}) => {

    const classes = useStyles();

    return (
        <Box className={classes.popoverContainer}
             key={thinkTankRealTableId}>

            <Typography color="textPrimary" style={{fontWeight: "bold"}}>
                Klick topic to update this table
            </Typography>

            {thinkTankTables.map((thisThinkTankTable) => {
              return(
                thisThinkTankTable.title != thinkTankTableTitle &&
              <Box className={classes.thinkTankTableContainer}
                   onClick={(event) => {
                     sendToParent(thinkTankRealTableId, thisThinkTankTable.id);
                   }}
              >
                 {thisThinkTankTable.title}
              </Box>
            )
            })}

        </Box>
    );
};
