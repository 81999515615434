import React from "react";
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popoverContainer: {
            padding: 10,
        },
        preferenceContainer: {
            padding: 10,
            borderRadius: 10,
            marginTop: 10,
            backgroundColor: theme.palette.background.default,
            display: "flex",
            alignItems: "center",
        },
        indicator: {
            height: 10,
            width: 10,
            borderRadius: 5,
            marginRight: 10,
        },
        errorIndicator: {
            height: 10,
            width: 10,
            borderRadius: 5,
            marginRight: 10,
            backgroundColor: theme.palette.error.dark
        },
    })
);


export const DinnerParticipantDetailView: React.FC<
                        {name: string,
                         title: string,
                         company: string,
                         dietary_needs?: string,
                         important_information?: string,
                         error_messages?: string[]}
                         > = (
                           {name,
                             title,
                             company,
                             dietary_needs,
                             important_information,
                             error_messages}) => {

    const classes = useStyles();

    return (
        <Box className={classes.popoverContainer}>
            <Typography color="textPrimary" style={{fontWeight: "bold"}}>
                {name}
            </Typography>
            <Typography color="textSecondary">
                {title}, {company}
            </Typography>

            {dietary_needs &&
                <Box className={classes.preferenceContainer}>
                   {dietary_needs}
                </Box>
            }

            {important_information &&
                <Box className={classes.preferenceContainer}>
                   {important_information}
                </Box>
            }

            {(error_messages) &&
            error_messages?.map(
              (error_message: string) => (
            <Box className={classes.preferenceContainer}>
                <Box
                    className={classes.errorIndicator}
                />
               <Typography color="textPrimary" style={{fontWeight: "bold"}}>
                   {error_message}
               </Typography>

            </Box>
              )
            )
            }
        </Box>
    );
};
