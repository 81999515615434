import React, { useState, useEffect } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Button,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Table } from "./Table";
import Config from "../../config";
import {socket} from "./webSocket";
import {CountdownComponent} from "./CountdownComponent";
import {utcDateToLocalDate} from "./../../utils/dateFunctions";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 20,
    },
    round: {
      marginBottom: 20,
    },
    tableContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    errorContainer: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fa897b",
      color: "#ffffff",
      borderRadius: 20,
      padding: 20,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
    },
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    listContainer: {
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    legendRow: {
      display: "flex",
      alignItems: "center",
    },
    row: {
      display: "flex",
      alignItems: "center",
    },
    column: {
      display: "flex",
      flexDirection: "column",
    },
    indicator: {
      height: 14,
      width: 14,
      borderRadius: 7,
      marginRight: 7,
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
    toggleGroup: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "10px !important",
      paddingLeft: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    toggleButton: {
      border: "none",
      borderRadius: "8px !important",
      textTransform: "none",
      paddingLeft: 16,
      paddingRight: 16,
      marginRight: 5,
      "&.Mui-selected": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    spacer: {
      flex: 1,
    },
  })
);



export const TableOverview: React.FC<{
  realTables: any;
}> = ({
  realTables
}) => {
  const classes = useStyles();

  const [selectedRound, setSelectedRound] = useState<number>(1);

  const [socketConnected, setSocketConnected] = useState(socket.connected);

  const [updatedRealTables, setUpdatedRealTables] = useState(
                               realTables.filter((thisRealTable) => thisRealTable.think_tank_title !== 'Not seated'));

  const startLiveVoting = () => {
    if(!socket.connected){
      socket.connect();
    }
    socket.emit("open_voting", {'round_number': selectedRound,
                                'event_id': Config.getInstance().getEventID()})

  }

  const stopLiveVoting = () => {
    if(!socket.connected){
      socket.connect();
    }
    socket.emit("close_voting", {'round_number': selectedRound,
                                'event_id': Config.getInstance().getEventID()})

  }

  const connectWebsocket = () => {
    if(!socket.connected){
      socket.connect();
    }
  }

  const disconnectWebsocket = () => {
    socket.disconnect();
  }

  useEffect(() => {


    //all functions to be defined on certain messages
    const onConnect = () => {
      setSocketConnected(true);
    };

    const onDisconnect= () => {
      setSocketConnected(false);
    };

    const onConnectResponse = (data) => {
      const roomIds = updatedRealTables.map((thisRealTable) => thisRealTable.id);
      socket.emit("join_rooms", {'room_ids': roomIds});
    }

    const onPublishMessageResponse = (data) => {
      const realTableId = data.dynamic_content_1.real_table_id;
      const previousRealTables = [...updatedRealTables];
      const newRealTables = previousRealTables.map(
            (thisRealTable) => {
              if(thisRealTable.id === realTableId){
                thisRealTable.dynamic_contents = [...thisRealTable.dynamic_contents, data.dynamic_content_1, data.dynamic_content_2];
                return thisRealTable
              } else {
                return thisRealTable
              }
            }

      )
      setUpdatedRealTables(newRealTables);
    }

    const onPublishVoteMessageResponse = (data) => {
      const realTableId = data.real_table_id;
      const previousRealTables = [...updatedRealTables];
      const newRealTables = previousRealTables.map(
            (thisRealTable) => {
              if(thisRealTable.id === realTableId){
                const prios = data.prios;
                const previousDynamicThinkTanks = [...thisRealTable.dynamic_contents];
                thisRealTable.dynamic_contents = previousDynamicThinkTanks.map((previousDynamicThinkTank) => {
                    const updatedDynamicThinkTank = prios.find((element) => element.id === previousDynamicThinkTank.id);
                       if(updatedDynamicThinkTank){
                          previousDynamicThinkTank.weight = updatedDynamicThinkTank.weight;
                        }
                        return previousDynamicThinkTank;
                        });
                return thisRealTable
              } else {
                return thisRealTable
              }
            }
      )
      setUpdatedRealTables(newRealTables);

    }

    const onJoinRooms = (data) => {};
    const onJoinRoomsResponse = (data) => {};

    const onOpenVoting = (data) => {
    };
    const onOpenVotingResponse = (data) => {
      const selectedRound = data['round_number']
      const newVotingEndTime = new Date(data['voting_end_time']);//here, we keep utc time
      const previousRealTables = [...updatedRealTables];
      const newRealTables = previousRealTables.map(
            (thisRealTable) => {
              if(thisRealTable.round_number === selectedRound){
                thisRealTable.voting_time_end = newVotingEndTime
                return thisRealTable
              } else {
                return thisRealTable
              }
            }
      )

      setEndVotingDatetime(utcDateToLocalDate(newVotingEndTime));
      setCountDown(utcDateToLocalDate(newVotingEndTime).getTime()-new Date().getTime());
      setUpdatedRealTables(newRealTables);
    };

     socket.on("connect", onConnect);
     socket.on("disconnect", onDisconnect);
     socket.on("connect_response", onConnectResponse);
     socket.on("publish_message_response", onPublishMessageResponse);
     socket.on("publish_vote_message_response", onPublishVoteMessageResponse);
     socket.on("join_rooms", onJoinRooms);
     socket.on("join_rooms_response", onJoinRoomsResponse);
     socket.on("open_voting", onOpenVoting);
     socket.on("open_voting_response", onOpenVotingResponse);

     return () => {
       socket.off("connect", onConnect);
       socket.off("disconnect", onDisconnect);
       socket.off("connect_response", onConnectResponse);
       socket.off("publish_message_response", onPublishMessageResponse)
       socket.off("publish_vote_message_response", onPublishVoteMessageResponse);
       socket.off("join_rooms", onJoinRooms);
       socket.off("join_rooms_response", onJoinRoomsResponse);
       socket.off("open_voting", onOpenVoting);
       socket.off("open_voting_response", onOpenVotingResponse);
     }

}, []);




    const [endVotingDatetime, setEndVotingDatetime] = useState<Date>(
      () => {
        const thisDate = new Date(realTables.filter((thisRealTable) =>
            thisRealTable.think_tank_title !== 'Not seated' &&
            thisRealTable.round_number === selectedRound
          )[0].voting_time_end)
        if(thisDate){
          return utcDateToLocalDate(thisDate);
        }
        return new Date();
      });
  const [countDown, setCountDown] = useState(endVotingDatetime.getTime() - new Date().getTime());

  useEffect(() => {
    if(countDown > 0){
    const interval = setInterval(() => {
      setCountDown(endVotingDatetime.getTime() - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }
  }, [countDown]);


  const changeSelectedRound = ((selectedRound: number) => {

    const thisDate = new Date(updatedRealTables.filter((thisRealTable) =>
            thisRealTable.think_tank_title !== 'Not seated' &&
            thisRealTable.round_number === selectedRound
          )[0].voting_time_end);
    let newEndVotingDatetime = new Date();
    if(thisDate){
      newEndVotingDatetime = utcDateToLocalDate(thisDate);
    }

    setEndVotingDatetime(newEndVotingDatetime);
    setCountDown(newEndVotingDatetime.getTime() - new Date().getTime());
    setSelectedRound(selectedRound);
  });


    return (
        <Box className={classes.listContainer} style={{ padding: 20 }}>

          <Box className={classes.row}>
              <CountdownComponent
                 countDown={countDown}
              />
          </Box>
          <Box>
            <Box className={classes.selectionContainer}>
              <Box className={classes.roundSelectionContainer}>
                <Box sx={{width: 200}}>
                  <ToggleButtonGroup
                    color="primary"
                    value={selectedRound}
                    exclusive
                    onChange={(_, value: number) => {
                      changeSelectedRound(value);
                    }}
                    size="small"
                    className={classes.toggleGroup}
                  >
                    <ToggleButton value={1} className={classes.toggleButton}>
                      Round 1
                    </ToggleButton>
                    <ToggleButton value={2} className={classes.toggleButton}>
                      Round 2
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <Box style={{flex: 1}} />
                <Button
                  style={{marginRight: 10}}
                  color="primary"
                  variant="contained"
                  onClick={() => startLiveVoting()}
                >
                  Start Live Voting
                </Button>

                <Button
                  style={{marginRight: 10}}
                  color="primary"
                  variant="contained"
                  onClick={() => stopLiveVoting()}
                >
                  Stop Live Voting
                </Button>

                <Button
                  style={{marginRight: 10}}
                  color="primary"
                  disabled={socketConnected}
                  variant="contained"
                  onClick={() => connectWebsocket()}
                >
                  Connect Websocket
                </Button>

                <Button
                  color="primary"
                  disabled={!socketConnected}
                  variant="contained"
                  onClick={() => disconnectWebsocket()}
                >
                  Disconnect from Websocket
                </Button>
              </Box>
            </Box>

            <Box className={classes.tableContainer}>
              {updatedRealTables.filter(
                   (thinkTankRealTable: any) => thinkTankRealTable.round_number === selectedRound && thinkTankRealTable.think_tank_title !== 'Not seated')
                .sort((thinkTankRealTableFirst: any, thinkTankRealTableSecond: any) => {
                      if (thinkTankRealTableFirst.table_number > thinkTankRealTableSecond.table_number){
                        return 1;
                      }
                      if (thinkTankRealTableFirst.table_number < thinkTankRealTableSecond.table_number){
                        return -1;
                      }
                      return 0;
                    })
                .map((thinkTankRealTable: any) => (
                  <Table
                    id={thinkTankRealTable.id}
                    key={thinkTankRealTable.id}
                    name={thinkTankRealTable.think_tank_name}
                    tableNumber={thinkTankRealTable.table_number}
                    title={thinkTankRealTable.think_tank_title}
                    topic={thinkTankRealTable.topic}
                    allDynamicContents={thinkTankRealTable.dynamic_contents}
                  />
                ))}
            </Box>


          </Box>
        </Box>
      )};
