import {io} from 'socket.io-client';
import Config from '../../config';

const wss_url = Config.getInstance().getWebsocketURL();

export const socket = io(wss_url, {
  autoConnect: false,
  transports: ["websocket"],  
})
/*, {
 transports: ["websocket"],
1});*/
