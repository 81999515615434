import {
  Box,
  createStyles,
  makeStyles,
  withStyles,
  Theme,
  Typography,
  LinearProgress,
} from "@material-ui/core";


const LinearProgressThick = withStyles((theme) => ({
  root: {height: 10},
}
))(LinearProgress);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //this is the actual table box
    container: {
      width: 380,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      margin: 10,
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      overflow: "auto",
    },
    //table box when think tank is booked
    containerBooked: {
      width: 380,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      margin: 10,
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      border: "2px solid #009688",
      overflow: "auto",
    },
    canvas: {
      width: 380,
      height: 380,
      position: "relative",
      marginBottom: 10,
    },
    table: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #00bcd4",
      color: "#00bcd4",
      borderRadius: "50%",
    },
    seat: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
      borderRadius: "50%",
      cursor: "pointer",
    },
    //this is the small circle indicating the BP
    badge: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 20,
      height: 20,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #fa897b",
      backgroundColor: theme.palette.background.paper,
    },
    errorIndicatorError: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #601b17",
      backgroundColor: theme.palette.error.dark,
    },
    errorIndicatorWarning: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #ed6c02",
      backgroundColor: theme.palette.warning.dark,
    },
    topicElementOuter: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.text.secondary,
      background: theme.palette.background.paper,
      marginLeft: 10,
      marginRight: 10,
      height: 90,
      left: 0,
      right: 0,
      borderRadius: 10,
      overflow: "visible",
      border: `2px solid ${theme.palette.text.secondary}`,
    },
    topicElementInner: {
      marginLeft: 10,
      overflow: "hidden",
      minWidth: 0,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      wordBreak: "break-all",
    },
    //topicElementBar and topicELementTitle (both including margins) have total height of topicElementOuter
    topicElementBar: {
      marginTop: 5,
      marginBottom: 5,
      height: 10,
    },
    topicElementTitle: {
      display: "flex",
      alignItems: "center",
      height: 70,
    },
    popoverContainer: {
      padding: 10,
    },
    preferenceContainer: {
      padding: 10,
      borderRadius: 10,
      marginTop: 10,
      backgroundColor: theme.palette.background.default,
      display: "flex",
      alignItems: "center",
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginRight: 10,
    },
  })
);

export const Table: React.FC<{
  id: string;   //the id of the table
  key: string;   //the key, here simply use the id
  name: string;  //the name of the think tank (e.g., T-1000)
  tableNumber: string;  //the table number
  title: string;
  topic: string;
  allDynamicContents: string[];
  style?: React.CSSProperties;
  className?: string;
}> = ({
  id,
  key,
  name,
  tableNumber,
  title,
  topic,
  allDynamicContents,
  style,
  className,
}) => {
  const classes = useStyles();


  const calcTotalWeight = () => {
    if(allDynamicContents.length === 0){
      return 0.
    } else{
      return [...allDynamicContents].map((thisDynamicContent) => thisDynamicContent.weight).reduce((a,b) => a+b);
    }
  }

  return (
    <Box
      key={key}
      style={style}
      className={classes.container}
    >

      <Box style={{ padding: 20}}>
        <Typography
          style={{ fontWeight: "bold" }}
          align="left"
          color="textPrimary"
          variant="h4"
        >
          Table Nr. {tableNumber} - Top 4
        </Typography>
        <Typography align="left" color="textSecondary" variant="h5">
          {title}
        </Typography>
      </Box>
      {allDynamicContents.length > 0 &&
        allDynamicContents?.sort((thisDynamicContentFirst, thisDynamicContentSecond) => {
        if (thisDynamicContentFirst.weight > thisDynamicContentSecond.weight){
          return -1;
        } else if(thisDynamicContentFirst.weight < thisDynamicContentSecond.weight){
          return 1;
        } else {
          return 0;
        }
      })
        .slice(0,4)
        .map((thisDynamicContent, index) => {
        return (
          <Box>
            <Box className={classes.topicElementOuter}>
              <Box className={classes.topicElementInner}>
              <Box className={classes.topicElementBar}>
              <LinearProgressThick variant="determinate" color="primary" value={calcTotalWeight() === 0 ? 0 : thisDynamicContent.weight/calcTotalWeight()*100} />
              </Box>
              <Box className={classes.topicElementTitle} >
                <Typography
                  align="left"
                  color="textPrimary"
                  className={classes.name}
                  variant="h4"
                  style={{ fontWeight: "bold"}}
                >
                 {thisDynamicContent.topic}
                </Typography>
                </Box>
              </Box>
            </Box>
          <Box style={{height: 10}}>
            </Box>
            </Box>
        );
      })}
      </Box>
  );
};
