import React, { useState } from "react";
import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  TextField,
  InputAdornment,
  Typography,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import CropFreeRoundedIcon from "@material-ui/icons/CropFreeRounded";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Config from "../../config";
import { ProfileJSON } from "../../interfaces";
import { ScannerDialog } from "./ScannerDialog";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { LinearProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
      overflow: "auto",
      paddingTop: 0,
    },
    searchFieldContainer: {
      padding: 20,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    listContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      overflow: "auto",
    },
    row: {
      display: "flex",
      alignItems: "center",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 10,
      borderRadius: 10,
      "&:hover": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
  })
);

export const Registrations = () => {
  const classes = useStyles();

  const queryClient = useQueryClient();

  const accessRights = localStorage.getItem("access_rights");

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isRetrieveRegistrationsLoading, data } = useQuery(
    "registrations",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/profiles/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const { isLoading: isUpdateRegistrationLoading, mutate } = useMutation(
    ({
      profileId,
      thinkRound1,
      thinkRound2,
      dinnerRound1,
      dinnerRound2,
      profile,
    }: {
      profileId: string;
      thinkRound1: {value: boolean, profileId: string, changed: boolean};
      thinkRound2: {value: boolean, profileId: string, changed: boolean};
      dinnerRound1: {value: boolean, profileId: string, changed: boolean};
      dinnerRound2: {value: boolean, profileId: string, changed: boolean};
      profile: ProfileJSON;
    }) => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      if (thinkRound1.profileId === profileId) {
        thinkRound1.changed === true
          ? profile.attend_think_round1 = thinkRound1.value
          : console.log()
      }
      if (thinkRound2.profileId === profileId) {
        thinkRound2.changed === true
          ? profile.attend_think_round2 = thinkRound2.value
          : console.log()
      }
      if (dinnerRound1.profileId === profileId) {
        dinnerRound1.changed === true
          ? profile.attend_dinner_round1 = dinnerRound1.value
          : console.log()
      }
      if (dinnerRound2.profileId === profileId) {
        dinnerRound2.changed === true
          ? profile.attend_dinner_round2 = dinnerRound2.value
          : console.log()
      }

      profile.origin = "event_dashboard";

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/registrations/${profileId}/`,
        {
          method: "PUT",
          headers: headers,
          body: JSON.stringify(profile),
        }
      ).then((res) => res.json());
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("registrations", (profiles) => {
          const castedData = data as ProfileJSON;
          const castedProfiles = profiles as ProfileJSON[];

          enqueueSnackbar("Registrations successfully updated.", {
            variant: "success",
          });

          return castedProfiles.map((existingProfile) => {
            if (existingProfile.id === castedData.id) {
              return castedData;
            }

            return existingProfile;
          });
        });
      },
    }
  );

  const { isLoading: isRetrieveEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const profiles = (data || []) as ProfileJSON[];

  const participationStatusMap: { [key: string]: string } = {
    "0% Confirmed": "Declined",
    "20% Confirmed": "Declined",
    "50% Confirmed": "50% Confirmed",
    "90% Confirmed": "Tentative",
    "100% Confirmed": "Accepted",
    "100% Confirmed - no show": "No show",
    "at Event": "Checked In",
    "Cancellation - no reason": "Declined",
    "Cancellation - other important meeting": "Declined",
    "Cancellation - sick": "Declined",
    "Cancellation - other reason": "Declined",
    "REGI IN - re-booking": "Accepted"
  };

  const colorMap: { [key: string]: string } = {
    Accepted: "#1DACD6",
    Tentative: "#fbc531",
    Declined: "#FE5E55",
  };

  const [searchText, setSearchText] = useState<string>("");
  const [showScanner, setShowScanner] = useState<boolean>(false);

  const groupedProfiles = profiles
    .filter((profile) => {
      return (
        profile.name_on_badge.includes(searchText) ||
        profile.company_on_badge.includes(searchText)
      );
    })
    .map((profile) => ({
      ...profile,
      participation_status:
        participationStatusMap[profile.participation_status],
    }))
    .reduce(
      (acc, profile) => ({
        ...acc,
        [profile.participation_status]: acc[
          profile.participation_status
        ]
          ? [...acc[profile.participation_status], profile]
          : [profile],
      }),
      {} as { [key: string]: ProfileJSON[] }
    );

  const [thinkRound1, setThinkRound1] = React.useState({value: false,
                                                        profileId: "",
                                                        changed: false});
  const [thinkRound2, setThinkRound2] = React.useState({value: false,
                                                        profileId: "",
                                                        changed: false});

  const [dinnerRound1, setDinnerRound1] = React.useState({value: false,
                                                          profileId: "",
                                                          changed: false});
  const [dinnerRound2, setDinnerRound2] = React.useState({value: false,
                                                          profileId: "",
                                                          changed: false});

  const isLoading =
    isRetrieveRegistrationsLoading ||
    isUpdateRegistrationLoading ||
    isRetrieveEventLoading;

  return (
    <Box className={classes.container}>
      <Box>
        <Box className={classes.searchFieldContainer}>
          <Typography color="textSecondary">{eventData?.name}</Typography>
          <Box style={{ flex: 1 }} />
          <TextField
            style={{ minWidth: 300 }}
            size="small"
            label="Search"
            variant="filled"
            InputProps={{
              disableUnderline: true,
              endAdornment: searchText ? (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchText("")}>
                    <ClearRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <SearchRoundedIcon />
                </InputAdornment>
              ),
            }}
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          <IconButton
            size="small"
            style={{ marginLeft: 20 }}
            onClick={() => setShowScanner(true)}
          >
            <CropFreeRoundedIcon />
          </IconButton>
        </Box>
        {isLoading && <LinearProgress color="primary" />}
      </Box>
      <ScannerDialog
        open={showScanner}
        onClose={() => setShowScanner(false)}
        checkInRegistrationID={(profileId: string) => {
          const profile = profiles.find(
            (profile) => profile.id === profileId
          );

          if (!profile) {
            enqueueSnackbar("Invalid event badge", { variant: "error" });
            return;
          }

          mutate({
            profileId: profile.id,
            thinkRound1: thinkRound1,
            thinkRound2: thinkRound2,
            dinnerRound1: dinnerRound1,
            dinnerRound2: dinnerRound2,
            profile: {
              ...profile,
              participation_status: "at Event",
            },
          });
        }}
      />
      <Box className={classes.listContainer}>
        {Object.keys(groupedProfiles)
          .sort((first: string, second: string) => {
            if (first === "50% Confirmed") return 1;
            if (second === "50% Confirmed") return -1;
            if (first === "Declined") return 1;
            if (second === "Declined") return -1;
            if (first === "No show") return 1;
            if (second === "No show") return -1;
            if (first === "Checked In") return 1;
            if (second === "Checked In") return -1;
            if (first === "Tentative") return 1;
            if (second === "Tentative") return -1;
            if (first === "Accepted") return 1;
            if (second === "Accepted") return -1;

            return first.localeCompare(second);
          })
          .map((key: string, _: number, array: string[]) => (
            <Box key={key}>
              <Typography
                key={key}
                color="textPrimary"
                align="left"
                style={{ fontWeight: "bold", marginBottom: 10, marginTop: 20 }}
              >
                {`${key || "Unknown"} (${groupedProfiles[key].length} / ${
                  profiles.length
                })`}
              </Typography>
              {groupedProfiles[key]
                .sort((a, b) => a.name_on_badge.localeCompare(b.name_on_badge))
                .map((profile, index) => (
                  <Box key={index} className={classes.row}>
                    <Box
                      className={classes.indicator}
                      style={{ backgroundColor: colorMap[key] }}
                    />
                    <Box>
                      <Typography
                        align="left"
                        color="textPrimary"
                        className={classes.name}
                      >
                        {profile.name_on_badge}
                      </Typography>
                      <Typography align="left" color="textSecondary">
                        {profile.title_on_badge}, {profile.company_on_badge}
                      </Typography>
                    </Box>

          <Box
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >

                    <Box style={{ flex: 1,
                                  display: "flex",
                                  flexDirection: "row"}} >
                      {!isLoading && (
                        <FormGroup>
                         <FormControlLabel
                            control={
                              <Switch
                                aria-label="think_round1"
                                disabled={accessRights === "full" ? false :
                                          accessRights === "limited" ? false :
                                          accessRights === "read_only" ? true : true}
                                defaultChecked={profile.attend_think_round1}
                                onChange={(_, value) => setThinkRound1({value: value as boolean,
                                                            profileId: profile.id as string,
                                                            changed: true})}
                              />
                            }
                            label={<Typography color="textPrimary" className={classes.name}> TT Round 1 </Typography>}
                            labelPlacement="top"
                          />
                         </FormGroup>
                       )}
                      {!isLoading && (
                        <FormGroup>
                         <FormControlLabel
                            control={
                              <Switch
                                aria-label="think_round2"
                                disabled={accessRights === "full" ? false :
                                          accessRights === "limited" ? false :
                                          accessRights === "read_only" ? true : true}
                                defaultChecked={profile.attend_think_round2}
                                onChange={(_, value) => setThinkRound2({value: value as boolean,
                                                            profileId: profile.id as string,
                                                            changed: true})}
                              />
                            }
                            label={<Typography color="textPrimary" className={classes.name}> TT Round 2 </Typography>}
                            labelPlacement="top"
                          />
                         </FormGroup>
                       )}
                    </Box>

                    <Box style={{ flex: 1,
                                  display: "flex",
                                  flexDirection: "row" }} >
                      {!isLoading && (
                        <FormGroup>
                         <FormControlLabel
                            control={
                              <Switch
                                aria-label="dinner_round1"
                                disabled={accessRights === "full" ? false :
                                          accessRights === "limited" ? false :
                                          accessRights === "read_only" ? true : true}
                                defaultChecked={profile.attend_dinner_round1}
                                onChange={(_, value) => setDinnerRound1({value: value as boolean,
                                                            profileId: profile.id as string,
                                                            changed: true})}
                              />
                            }
                            label={<Typography color="textPrimary" className={classes.name}> Din Round 1 </Typography>}
                            labelPlacement="top"
                          />
                         </FormGroup>
                       )}
                      {!isLoading && (
                        <FormGroup>
                         <FormControlLabel
                            control={
                              <Switch
                                aria-label="dinner_round2"
                                disabled={accessRights === "full" ? false :
                                          accessRights === "limited" ? false :
                                          accessRights === "read_only" ? true : true}
                                defaultChecked={profile.attend_dinner_round2}
                                onChange={(_, value) => setDinnerRound2({value: value as boolean,
                                                            profileId: profile.id as string,
                                                            changed: true})}
                              />
                            }
                            label={<Typography color="textPrimary" className={classes.name}> Din Round 2 </Typography>}
                            labelPlacement="top"
                          />
                         </FormGroup>
                       )}
                    </Box>


                    <Box style={{ flex: 1 }} />
                      <Button
                        variant="contained"
                        disabled={accessRights === "full" ? false :
                                  accessRights === "limited" ? false :
                                  accessRights === "read_only" ? true : true}
                        color="primary"
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                          event.stopPropagation();
                          mutate({
                            profileId: profile.id,
                            thinkRound1: thinkRound1,
                            thinkRound2: thinkRound2,
                            dinnerRound1: dinnerRound1,
                            dinnerRound2: dinnerRound2,
                            profile: {
                              ...profile,
                            participation_status: "at Event",
                            },
                          });
                        }}
                      >
                        Update
                      </Button>
                  </Box>
              </Box>
                ))}
            </Box>
          ))}
      </Box>
    </Box>
  );
};
