export const daysUntilEvent = (eventDate: Date) => {
    const todayDate = new Date();
    const shiftedEventDate = new Date(eventDate);

    //set shifted event date to the date of the friday within the same week of event date
    if (eventDate.getDay() === 0) {shiftedEventDate.setDate(shiftedEventDate.getDate()-2) };
    if (eventDate.getDay() == 1) {shiftedEventDate.setDate(shiftedEventDate.getDate()+4)};
    if (eventDate.getDay() == 2) {shiftedEventDate.setDate(shiftedEventDate.getDate()+3)};
    if (eventDate.getDay() == 3) {shiftedEventDate.setDate(shiftedEventDate.getDate()+2)};
    if (eventDate.getDay() == 4) {shiftedEventDate.setDate(shiftedEventDate.getDate()+1)};
    if (eventDate.getDay() == 5) {shiftedEventDate.setDate(shiftedEventDate.getDate()+0)};
    if (eventDate.getDay() == 6) {shiftedEventDate.setDate(shiftedEventDate.getDate()-1)};

    //differences in days (note that getTime is in milliseconds)
    return (shiftedEventDate.getTime()-todayDate.getTime())/3600/24/1000;

  }


export const utcDateToLocalDate = ((thisDate: Date) => {
  //on input: date in utc
  //on output: local date
     const localDate = new Date(thisDate.getTime() - thisDate.getTimezoneOffset()*60000)
     return localDate;
   });
