/* const DEFAULT_HOST = "https://coordination-service.networkcircle.com"; */
const DEFAULT_HOST = "http://localhost:5001";
const DEFAULT_WEBSOCKET_URL = "http://192.168.178.97:5001/websocket_dynamic_think_tanks";
//const DEFAULT_HOST = "http://192.168.178.97:5001";

export default class Config {
  private static instance: Config;

  /**
   * Retrieve the shared config object
   * @return Config the shared config object
   */
  public static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config();
    }

    return Config.instance;
  }

  /**
   *  Retrieve the url of the coordination service
   *  @return string the url of the coordination service
   */
  getCoordinationServiceURL(): string {
    return process.env.REACT_APP_COORDINATION_SERVICE_URL || DEFAULT_HOST;
  }

  /**
   *  Retrieve the url of the websocket
   *  @return string the url of the websocket
   */
  getWebsocketURL(): string {
    return process.env.REACT_APP_WEBSOCKET_URL || DEFAULT_WEBSOCKET_URL;
  }

  /**
   * Retrieve the current event ID
   * @return string the current event ID
   */
  getEventID(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("event_id") || "";
  }

  /**
   * Retrieve the current registration ID
   * @return string the current registration ID
   */
  getRegistrationID(): string {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get("registration_id") || "";
  }

  /**
   * Retrieve the current authentication token
   * @return string the current authentication token
   */
  getToken(): string {
    return "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY0MTk5ODI5MywianRpIjoiNmUxMzZmMGMtZWYyNS00ZTUyLTk2OGMtZDFhNGVkNTYzNzA0IiwidHlwZSI6ImFjY2VzcyIsInN1YiI6ImEwTDNZMDAwMDBuTzRxSFVBUyIsIm5iZiI6MTY0MTk5ODI5M30.Mt2xhgvdMCcAd23DOml3Xx3Dk7J9e7OWaxketCSieQ0";
  }
}
