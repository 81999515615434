import React, { useEffect, useState } from "react";
import "./App.css";
import {
  AppBar,
  Toolbar,
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tabs,
  Tab,
  IconButton,
} from "@material-ui/core";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
//import SettingsIcon from "@material-ui/icons/Settings";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThinkTanks } from "./features/thinkTanks/ThinkTanks";
import { Registrations } from "./features/registrations/Registrations";
import { Dinner } from "./features/dinner/Dinner";
import { Settings } from "./features/settings/Settings";
import { DataAnalysis } from "./features/dataAnalysis/DataAnalysis";
import { LiveRegistrations } from "./features/liveRegistrations/LiveRegistrations";
import { LiveEvent } from "./features/liveEvent/LiveEvent";
import Logo from "./resources/logo.svg";
import { Authentication } from "./features/authentication/Authentication";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    routerContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create("padding", {
        easing: theme.transitions.easing.sharp,
        duration: 200,
      }),
    },
    eventName: {
      backgroundColor: theme.palette.background.paper,
      padding: 10,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    appbar: {
      borderBottom: `1px solid ${theme.palette.background.default}`,
      boxShadow: "none !important",
    },
    toolbar: {
      backgroundColor: theme.palette.background.paper,
    },
    toolbarElement: {
      minWidth: 150,
      display: "flex",
    },
    tabs: {
      flex: 1,
    },
    logo: {
      width: 120,
    },
  })
);

const history = createBrowserHistory();

function App() {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const AuthenticationContainer = ({ children }: { children: any }) => {
    const token = localStorage.getItem("access_token");

    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set("next", window.location.pathname);

    return token ? (
      children
    ) : (
      <Redirect
        to={{
          pathname: "/login/",
          search: urlSearchParams.toString(),
        }}
      />
    );
  };

  const routes = [
    {
      label: "Think Tanks",
      path: "/think-tanks/",
      component: ThinkTanks,
    },
    {
      label: "Dinner",
      path: "/dinner/",
      component: Dinner,
    },
    {
      label: "Registrations",
      path: "/registrations/",
      component: Registrations,
    },
    {
      label: "Settings",
      path: "/settings/",
      component: Settings,
    },
    {
      label: "Data Analysis",
      path: "/data-analysis/",
      component: DataAnalysis,
    },
    {
      label: "Live Registrations",
      path: "/live-registrations/",
      component: LiveRegistrations,
    },
    {
      label: "Live Event",
      path: "/live-event/",
      component: LiveEvent,
    },
  ];

  useEffect(() => {
    let index = routes.findIndex((route) =>
      history.location.pathname.includes(route.path)
    );
    setSelectedTab(index);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className="App">
      <AppBar position="static" className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Box
            className={classes.toolbarElement}
            style={{ justifyContent: "flex-start" }}
          >
            <img alt="logo" src={Logo} className={classes.logo} />
            </Box>
          <Tabs
            className={classes.tabs}
            value={selectedTab}
            onChange={(event, value) => {
              setSelectedTab(value);
              history.push({
                pathname: routes[value].path,
                search: history.location.search,
              });
            }}
            indicatorColor="primary"
            orientation="horizontal"
            scrollButtons="auto"
            variant="scrollable"
          >
            {routes.map((route, index) => (
              <Tab key={index} label={route.label} color="primary" />
            ))}

          </Tabs>
          <Box
            className={classes.toolbarElement}
            style={{ justifyContent: "flex-end" }}
          >

{/*}            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                localStorage.removeItem("token");
                history.push({
                  pathname: "/login/",
                  search: history.location.search,
                });
              }}
            >
              <SettingsIcon />
            </IconButton> */}

            <IconButton
              color="inherit"
              size="small"
              onClick={() => {
                localStorage.removeItem("access_token");
                localStorage.removeItem("access_rights");
                localStorage.removeItem("sse_access_token");
                history.push({
                  pathname: "/login/",
                  search: history.location.search,
                });
              }}
            >
              <ExitToAppRoundedIcon />
            </IconButton>
          </Box>



        </Toolbar>
      </AppBar>
      <main className={classes.routerContainer}>
        <Router history={history}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                exact
                path={route.path}
                render={() => (
                  <AuthenticationContainer>
                    <route.component />
                  </AuthenticationContainer>
                )}
              />
            ))}
            <Route exact path="/login/" component={Authentication} />
            <Redirect
              from="/"
              to={{
                pathname: routes[0].path,
                search: history.location.search,
              }}
            />
          </Switch>
        </Router>
      </main>
    </Box>
  );
}

export default App;
