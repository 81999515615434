import React, { useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Popover } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { ParticipantDetailView } from "./ParticipantDetailView";
import { ThinkTankRealTableDetailView } from "./ThinkTankRealTableDetailView";
import { Participant, ParticipantWithErrorMessage } from "../../interfaces";
import WarningAmber from "../../resources/warning_amber.svg";
import InfoIcon from "../../resources/info.svg";

import {
  Switch,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    //this is the actual table box
    container: {
      width: 380,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      margin: 10,
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      overflow: "auto",
    },
    //table box when think tank is booked
    containerBooked: {
      width: 380,
      height: 600,
      backgroundColor: theme.palette.background.paper,
      margin: 10,
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      border: "2px solid #009688",
      overflow: "auto",
    },
    canvas: {
      width: 380,
      height: 380,
      position: "relative",
      marginBottom: 10,
    },
    table: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #00bcd4",
      color: "#00bcd4",
      borderRadius: "50%"
    },
    tableClickable: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      border: "2px solid #00bcd4",
      color: "#00bcd4",
      borderRadius: "50%",
      cursor: "pointer"
    },
    seat: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
      justifyContent: "center",
      borderRadius: "50%",
      cursor: "pointer",
    },
    //this is the small circle indicating the BP
    badge: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 20,
      height: 20,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #fa897b",
      backgroundColor: theme.palette.background.paper,
    },
    errorIndicatorError: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #601b17",
      backgroundColor: theme.palette.error.dark,
    },
    errorIndicatorWarning: {
      position: "absolute",
      left: 0,
      top: 0,
      width: 22,
      height: 22,
      borderRadius: 10,
      boxSizing: "border-box",
      border: "2px solid #ed6c02",
      backgroundColor: theme.palette.warning.dark,
    },
    popoverContainer: {
      padding: 10,
    },
    preferenceContainer: {
      padding: 10,
      borderRadius: 10,
      marginTop: 10,
      backgroundColor: theme.palette.background.default,
      display: "flex",
      alignItems: "center",
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      marginRight: 10,
    },
  })
);

export const Table: React.FC<{
  id: string;   //the id of the table
  access_rights: string;
  showDetailedInformation: boolean;
  weight_map: {'Prio1': number,
               'Prio2': number,
               'Prio3': number,
               'Prio4': number};
  name: string;  //the name of the think tank (e.g., T-1000)
  tableNumber: string;  //the table number
  bookedTable: boolean;
  sendDataToParent: (arg1: string, arg2: number) => void;
  updateThinkTankTitle: (thinkTankRealTableId: string, thinkTankTableId: string) => void;
  title: string;
  tableRadius?: number;
  seatRadius?: number;
  seatToTablePadding?: number;
  registrations: ParticipantWithErrorMessage[];
  thinkTankTables: any[];
  style?: React.CSSProperties;
  className?: string;
}> = ({
  id,
  access_rights,
  showDetailedInformation,
  weight_map,
  name,
  tableNumber,
  bookedTable,
  sendDataToParent,
  updateThinkTankTitle,
  title,
  tableRadius = 70,
  seatRadius = 50,
  seatToTablePadding = 10,
  registrations,
  thinkTankTables,
  style,
  className,
}) => {
  const classes = useStyles();


//  console.log("registrations", registrations);
//  console.log(registrations[0].priorities.filter((thisElement: any) => thisElement !== null).length);

  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
  const [anchorElementTable, setAnchorElementTable] = useState<HTMLElement | null>(null);
  const [selectedParticipant, setSelectedParticipant] =
    useState<ParticipantWithErrorMessage | null>(null);

  /**
   *
   * @param radius
   * @param angle
   * @param referenceFrame
   */
  const polarToCartesian = (
    radius: number,
    angle: number,
    referenceFrame: {
      x: number;
      y: number;
    } = { x: 0, y: 0 }
  ): { x: number; y: number } => {
    return {
      y: referenceFrame.x + Math.cos(angle) * radius,
      x: referenceFrame.y + Math.sin(angle) * radius,
    };
  };

  const weightToColorMap2 = { "Prio1": weight_map.Prio1,
                              "Prio2": weight_map.Prio2,
                              "Prio3": weight_map.Prio3,
                              "Prio4": weight_map.Prio4};

  const getColorWeight = (
    weight: number,
    weight_map: {
      Prio1: number;
      Prio2: number;
      Prio3: number;
      Prio4: number;
    }
): string => {
    let colorString = "#e91e63";
    if(weight_map.Prio1 === weight){colorString="#009688";};
    if(weight_map.Prio2 === weight){colorString="#cddc39";};
    if(weight_map.Prio3 === weight){colorString="#3f51b5";};
    if(weight_map.Prio4 === weight){colorString="#00bcd4";};

    return colorString;
      };

  const getTooltipWeight = (
    weight: number,
    weight_map: {
      Prio1: number;
      Prio2: number;
      Prio3: number;
      Prio4: number;
    }
): string => {
    let tooltipString = "No preference";
    if(weight_map.Prio1 === weight){tooltipString="Preference 1";};
    if(weight_map.Prio2 === weight){tooltipString="Preference 2";};
    if(weight_map.Prio3 === weight){tooltipString="Preference 3";};
    if(weight_map.Prio4 === weight){tooltipString="Preference 4";};

    return tooltipString;
      };


/*  const weightToColorMap: { [key: number]: string } = {
    2.0: "#009688",
    1.0: "#cddc39",
    0.5: "#3f51b5",
    0.25: "#00bcd4",
    0.0: "#e91e63",
  };

  const weightToTooltipMap: { [key: number]: string } = {
    2.0: "Preference 1",
    1.0: "Preference 2",
    0.5: "Preference 3",
    0.25: "Preference 4",
    0.0: "No preference",
  }; */

  const showPopover = Boolean(anchorElement);
  const showPopoverTable = Boolean(anchorElementTable);

//  const handleChange = (event: any) => sendDataToParent(event.target.id, event.target.value);

  const [timer, setTimer] = useState<any>();

  const handleChange = (event: any) => {
    if (event.target.value !== "") {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        sendDataToParent(event.target.id, event.target.value);
      }, 250)
      setTimer(newTimer)
    };
    }

  const updateThinkTankRealTableTitle = (thinkTankRealTableId: string, thinkTankTableId: string) => {
    //change topic for think tank real table with corresponding id
    //to think tank table with corresponding id
    setAnchorElementTable(null);
    updateThinkTankTitle(thinkTankRealTableId, thinkTankTableId);
  }

  return (
    <Box
      key={id}
      style={style}
      className={
        bookedTable && showDetailedInformation === true ?
            `${classes.containerBooked} ${className}`
                    :
            `${classes.container} ${className}`
          }
    >
      <Popover
        id={showPopover ? `registration-${selectedParticipant?.id}` : undefined}
        open={showPopover}
        anchorEl={anchorElement}
        onClose={(event: any) => {
          event.preventDefault();
          event.stopPropagation();
          setAnchorElement(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ParticipantDetailView
            name={selectedParticipant?.name || ""}
            title={selectedParticipant?.title || ""}
            company={selectedParticipant?.company || ""}
            color={
              selectedParticipant?.priorities.filter((thisElement: any) => thisElement !== null).length === 0 ?
                  "white"
                  :
              getColorWeight(selectedParticipant?.weight || 0, weight_map)}
            preference={
              selectedParticipant?.priorities.filter((thisElement: any) => thisElement !== null).length === 0 ?
                  "No preference submitted"
                  :
              getTooltipWeight(selectedParticipant?.weight || 0, weight_map)}
            error_messages={selectedParticipant?.related_errors}
        />
        {access_rights == "full" &&
        <Box className={classes.preferenceContainer}>
        <TextField
           id={selectedParticipant?.id || "null"}
           label="Table Number"
           type="number"
           defaultValue={tableNumber}
           InputLabelProps={{
             shrink: true,
           }}
            onChange={handleChange}
        />
        </Box>
      }
      </Popover>

      <Popover
        id={`table-${id}`}
        open={showPopoverTable}
        anchorEl={anchorElementTable}
        onClose={(event: any) => {
          event.preventDefault();
          event.stopPropagation();
          setAnchorElementTable(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {access_rights == "full" &&
          <ThinkTankRealTableDetailView
             thinkTankRealTableId={id}
             thinkTankTableTitle={title}
             sendToParent={updateThinkTankRealTableTitle}
             thinkTankTables={thinkTankTables}
          />
      }
      </Popover>


      <Box style={{ padding: 20, flex: 1}}>
        <Typography
          style={{ fontWeight: "bold" }}
          align="left"
          color="textPrimary"
        >
          {name} {bookedTable && showDetailedInformation === true ? " -- BOOKED" : ""}
        </Typography>
        <Typography align="left" color="textSecondary">
          {title}
        </Typography>
      </Box>
      <Box className={classes.canvas}>
      {access_rights == "full" &&
        <Box
          id={id}
          className={classes.tableClickable}
          style={{
            top: 380 / 2 - tableRadius,
            left: 380 / 2 - tableRadius,
            width: 2 * tableRadius,
            height: 2 * tableRadius,
          }}
          onClick={(event) => {
              setAnchorElementTable(event.currentTarget);
          }}
        >
          <Typography
            align="center"
            color="textSecondary"
            style={{ fontSize: 12 }}
          >
            Table Nr. {tableNumber}
          </Typography>
        </Box>
      }
      {access_rights != "full" &&
        <Box
          id={id}
          className={classes.table}
          style={{
            top: 380 / 2 - tableRadius,
            left: 380 / 2 - tableRadius,
            width: 2 * tableRadius,
            height: 2 * tableRadius,
          }}
        >
          <Typography
            align="center"
            color="textSecondary"
            style={{ fontSize: 12 }}
          >
            Table Nr. {tableNumber}
          </Typography>
        </Box>
      }
        {registrations.map((registration, index) => {
          const radiusFraction = index / registrations.length;

          const coordinates = polarToCartesian(
            tableRadius + seatToTablePadding + seatRadius,
            radiusFraction * 2 * Math.PI,
            {
              x: 380 / 2,
              y: 380 / 2,
            }
          );

          return (
            <Box
              key={index}
              className={classes.seat}
              onClick={(event) => {
                setAnchorElement(event.currentTarget);
                setSelectedParticipant(registration);
              }}
              style={{
                width: 2 * seatRadius,
                height: 2 * seatRadius,
                top: coordinates.y - seatRadius,
                left: coordinates.x - seatRadius,
                border: `2px solid ${
                  showDetailedInformation === false ?
                    "white"
                  :
                  registration?.priorities.filter((thisElement: any) => thisElement !== null).length === 0
                  ?
                  "white"
                  :
                  getColorWeight(registration?.weight || 0, weight_map)}`,
                color: getColorWeight(registration?.weight || 0, weight_map),
              }}
            >
              {registration.type !== "Executive Registration" &&
               showDetailedInformation === true &&
              (
                <Box
                  className={classes.badge}
                  style={{
                    top: seatRadius - 0.7854 * seatRadius - 0.7854 * 10,
                    left: seatRadius - 0.7854 * seatRadius - 0.7854 * 10,
                  }}
                />
              )}
              {registration.related_errors.length > 0 &&
               Math.max(...registration.related_error_severities) === 10 &&
               showDetailedInformation === true &&
                (
               <img
                   alt="logo"
                   className={classes.errorIndicatorError}
                   style={{
                      top: seatRadius - 0.7854 * seatRadius - 0.7854 * 10,
                      left: seatRadius + 0.7854 * seatRadius - 0.7854 * 10,
                    }}
                    src={WarningAmber}
              />
              )}
              {registration.related_errors.length > 0 &&
               Math.max(...registration.related_error_severities) === 0 &&
               showDetailedInformation === true &&
                (
               <img
                   alt="logo"
                   className={classes.errorIndicatorWarning}
                   style={{
                      top: seatRadius - 0.7854 * seatRadius - 0.7854 * 10,
                      left: seatRadius + 0.7854 * seatRadius - 0.7854 * 10,
                    }}
                    src={WarningAmber}
              />
              )}

              <Typography
                align="center"
                color="textSecondary"
                style={{ fontSize: 12 }}
              >
                {registration.name}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
