import { createTheme } from "@material-ui/core";

export const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#1DACD6",
      contrastText: "#f5f6fa",
    },
    secondary: {
      main: "#FE5E55",
      contrastText: "#f5f6fa",
    },
    text: {
      primary: "#f5f6fa",
      secondary: "#f5f6faAA",
      primaryDark: "#05060a",      //dark font if background is light
      secondaryDark: "#2d2e32"   //dark font if background is light
    },
    background: {
      default: "#25262E",
      paper: "#2D3038",
    },
    success: {
      main: "#2CD58E",
    },
    info: {
      main: "#2CD58E",
      dark: "#2CD58E",
    },
    error: {
      main: "#FE5E55",
//      dark: "#601b17",
      dark: "#e91e63",
    },
    warning: {
      main: "#ed6c02",
      dark: "#ff9800",
    },
  },
  spacing: [4, 8, 12, 16],
  typography: {
    fontSize: 14,
    // fontFamily: `"JakartaSans", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 400,
    fontWeightBold: 400,
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
    button: {
      fontSize: 14,
    },
    h4: {
      fontSize: 28,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "10px",
        boxShadow: "none !important",
        textTransform: "none",
        paddingLeft: "12 !important",
        paddingRight: "12 !important",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "20px",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0px 20px 0px 20px",
      },
    },
    MuiDialogActions: {
      root: {
        padding: 20,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 20,
      },
    },
    MuiTableCell: {
      sizeSmall: {
        padding: "6px 10px 6px 10px",
      },
    },
    MuiTableContainer: {
      root: {
        boxShadow: "none !important",
      },
    },
    MuiTablePagination: {
      root: {
        borderBottom: "none",
      },
    },
    MuiMenu: {
      paper: {
        padding: 10,
        borderRadius: 10,
      },
      list: {
        padding: "0 !important",
      },
    },
    MuiMenuItem: {
      root: {
        borderRadius: 10,
        padding: "10px !important",
        "&:hover": {
          backgroundColor: "#FAFAFD",
        },
        "&$selected": {
          backgroundColor: "#FAFAFD",
        },
      },
    },
    // @ts-ignore
    MuiTreeItem: {
      label: {
        backgroundColor: "rgba(0, 0, 0, 0.0) !important",
        fontWeight: "bold !important",
        fontSize: "14px",
      },
      group: {
        marginLeft: "22px",
      },
      /*
            root: {
                padding: "8px 0px !important"
            }
            */
    },
    MuiTab: {
      root: {
        textTransform: "none",
        padding: "20px 10px",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "20px !important",
        paddingRight: "20px !important",
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: "rgba(0, 0, 0, 0.0) !important",
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 10,
        backgroundColor: "#25262E !important",
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 10,
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: "#25262E",
      },
    },
    MuiInputBase: {
      root: {
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
      },
    },
    MuiToggleButton: {
      root: {
        border: "2px solid #2D3038",
        "Mui-selected": {
          backgroundColor: "#ff0000 !important",
        },
      },
    },
  },
});
