export const viridis = ((xMin, xMax, x: number) => {
  //get rgb colours for viridis colour map
  //on input:
  //   xmin: value describing minimum of colorbar
  //   xmax: value describing maximum of colorbar
  //   x: value for which rgb colours shall be calculated
  //on output: array [r,g,b] each within [0,255]
  //
  const r_colours = [68, 70, 72, 67, 62, 56, 49, 44, 38, 35, 31, 42, 53, 81, 109, 145, 180, 217, 253];
  const g_colours = [1, 21, 40, 57, 74, 89, 104, 117, 130, 144, 158, 155, 153, 179, 205, 214, 222, 227, 231];
  const b_colours = [84, 102, 120, 129, 137, 140, 142, 142, 142, 140, 137, 129, 121, 105, 89, 67, 44, 40, 37];

  //calculate index
  const indxMin = 0;
  const indxMax = r_colours.length - 1;

  let indx = 0;
  if(xMin !== xMax){
    indx = indxMin + (indxMax - indxMin)/(xMax - xMin) * (x - xMin)
    indx = Math.round(indx);
    indx = Math.min(indxMax, indx);
    indx = Math.max(indxMin, indx);
  }


  return [r_colours[indx], g_colours[indx], b_colours[indx]];
   });
