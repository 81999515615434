import React from "react";
import {
    Box,
    createStyles,
    makeStyles,
    Theme,
    Typography,
} from "@material-ui/core";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        popoverContainer: {
            padding: 10,
        },
        preferenceContainer: {
            padding: 10,
            borderRadius: 10,
            marginTop: 10,
            backgroundColor: theme.palette.background.default,
            display: "flex",
            alignItems: "center",
        },
        indicator: {
            height: 10,
            width: 10,
            borderRadius: 5,
            marginRight: 10,
        },
        errorIndicator: {
            height: 10,
            width: 10,
            borderRadius: 5,
            marginRight: 10,
            backgroundColor: theme.palette.error.dark
        },
    })
);


export const ParticipantDetailView: React.FC<{name: string, title: string, company: string, color?: string, preference?: string, error_messages?: string[]}> = ({name, title, company, color, preference, error_messages}) => {

    const classes = useStyles();

    return (
        <Box className={classes.popoverContainer}
             key={name}>
            <Typography color="textPrimary" style={{fontWeight: "bold"}}>
                {name}
            </Typography>
            <Typography color="textSecondary">
                {title}, {company}
            </Typography>
            {(color && preference) &&
            <Box className={classes.preferenceContainer}>
                <Box
                    className={classes.indicator}
                    style={{ backgroundColor: color }}
                />
                <Typography color="textSecondary">
                    {preference}
                </Typography>
            </Box>
            }


            {(error_messages) &&
            error_messages?.map(
              (error_message: string) => (
            <Box className={classes.preferenceContainer}>
                <Box
                    className={classes.errorIndicator}
                />
               <Typography color="textPrimary" style={{fontWeight: "bold"}}>
                   {error_message}
               </Typography>

            </Box>
              )
            )
            }
        </Box>
    );
};
