//import React, { useEffect } from "react";
import {
  Box,
  Button,
  createStyles,
//  IconButton,
  makeStyles,
  Theme,
//  TextField,
//  InputAdornment,
  Typography,
//  Divider,
//  Slider,
//  Switch,
//  FormGroup,
//  FormControlLabel
} from "@material-ui/core";
//import CropFreeRoundedIcon from "@material-ui/icons/CropFreeRounded";
//import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { useMutation,
         useQuery,
//         useQueryClient
        } from "react-query";
import React, { useState, useEffect } from "react";
import Config from "../../config";
//import { SettingsJSON } from "../../interfaces";
//import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
//import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { LinearProgress } from "@material-ui/core";
//import { useSnackbar } from "notistack";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexDirection: "column",
      display: "flex",
      overflow: "hidden",
    },
    controlContainer: {
      backgroundColor: theme.palette.background.paper,
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    rowContainer: {
      overflow: "visible",
      position: "relative",
      userSelect: "none",
    },
    searchFieldContainer: {
      padding: 20,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.background.default}`,
    },
    registrationContainer: {
      left: -6,
      right: -6,
      top: -6,
      height: 278,
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: 10,
      position: "absolute",
    },
    listContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
    },
    scrollContainer: {
      paddingBottom: 20,
    },
    selectionContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    roundSelectionContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderRadius: 10,
      marginBottom: 10,
    },
/*    sliderContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      width: "100%",
    },*/
sliderContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      height: 100,
      width: 300,
    },
    row: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 10,
      borderRadius: 10,
      backgroundColor: theme.palette.background.paper,
    },
    indicator: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
      marginRight: 10,
    },
    name: {
      fontWeight: "bold",
    },
    linkedIn: {
      height: 20,
      width: 20,
    },
  })
);



export const LiveRegistrations = () => {
  const classes = useStyles();

//  const queryClient = useQueryClient();

//  const { enqueueSnackbar } = useSnackbar();

  const sse_access_token = localStorage.getItem("sse_access_token");

    const { isLoading: isRetrieveEventLoading, data: eventData } = useQuery(
    "event",
    () => {
      const headers = new Headers();

      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${Config.getInstance().getCoordinationServiceURL()}/api/events/${Config.getInstance().getEventID()}/`,
        {
          headers: headers,
        }
      ).then((res) => res.json());
    }
  );

  const { isLoading: isRetrieveDataAnalysisLoading, mutate } = useMutation(
    (eventName: string) => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      const zip_file = eventName + ".zip";

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );

      return fetch(
        `${baseURL}/api/events/${eventID}/data_analysis/`,
        {
          method: "GET",
          headers: headers,
        }
      ).then(res => res.blob())
       .then(blob => {
         const url = window.URL.createObjectURL(blob);
         let a = document.createElement('a');
         a.href = url;
         a.download = zip_file;
         a.click();
       })
     },
    {
      onSuccess: () => {
      const baseURL = Config.getInstance().getCoordinationServiceURL();
      const eventID = Config.getInstance().getEventID();

      const headers = new Headers();

      headers.append("Accept", "application/json");
      headers.append("Content-Type", "application/json");
      headers.append(
        "Authorization",
        `Bearer ${Config.getInstance().getToken()}`
      );
      return fetch(
        `${baseURL}/api/events/${eventID}/data_analysis_delete/`,
        {
          method: "GET",
          headers: headers,
        }
      ).then((res) => res.json());
      },
    }
   );

  const isLoading =
    isRetrieveDataAnalysisLoading ||
    isRetrieveEventLoading;


  const [liveRegistrations, setLiveRegistrations] = React.useState([{name: "",
                                                                    title: "",
                                                                    company: ""}]);

  const sse_url = `${Config.getInstance().getCoordinationServiceURL()}/stream/${sse_access_token}?channel=${Config.getInstance().getEventID()}`;
//  const sse_url = `${Config.getInstance().getCoordinationServiceURL()}/stream?channel=${Config.getInstance().getEventID()}`;
//  const sse_url = 'http://127.0.0.1:5001/stream/MnKE3RQwUgXMMqQw63gJ?channel=a0M3Y00001JfNHgUAN'

//  console.log(Config.getInstance().getToken());
  console.log(sse_url)
//  console.log(sse_access_token)
  useEffect(() => {
    const sse = new EventSource(sse_url);
    sse.addEventListener("open", (e)=> {
      console.log('sse opened');
      })
    sse.addEventListener("error", (e)=> {
      console.log('sse: some error occured');
      })
    sse.addEventListener("message", (e)=> {
      setLiveRegistrations(JSON.parse(e.data));
      console.log(JSON.parse(e.data));
      })

  })
//  console.log(sse_url);
//  console.log("test");
//  console.log(liveRegistrations)


//  function getRealtimeData(data: any) {
//    console.log(data);
//    return data
//  }
//  sse.onmessage = (e) => {
//    console.log(e.data);
//  }
//  console.log(sse.onmessage);
//)
//  sse.onmessage = (e: any) => getRealtimeData(JSON.parse(e.data));



  return (
    <Box className={classes.container}>
    <Box className={classes.controlContainer}>
          <Typography
              color="textPrimary"
              align="center"
              variant="h5"
              style={{marginTop: 20}}
          >
          {eventData?.name}
          </Typography>
    </Box>


            <Box>
              <Typography
                color="textPrimary"
                align="left"
                style={{ fontWeight: "bold", marginBottom: 10, marginTop: 20 }}
              >
              Latest Check-Ins
              </Typography>
              </Box>

      <Box
        className={classes.listContainer}
      >


        {liveRegistrations.map((liveRegistration, liveRegistrationIndex) => (
      <Box key={liveRegistrationIndex} className={classes.row}>
      <Box>
                  <Typography color="textPrimary" align="center">
                    {liveRegistration.name}
                  </Typography>
                  <Typography color="textSecondary" align="center">
                    {liveRegistration.title}
                  </Typography>
                  <Typography color="textSecondary" align="center">
                    {liveRegistration.company}
                  </Typography>
                  </Box>
        </Box>
            ))}

      </Box>

    </Box>
  )
}
