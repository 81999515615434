import React, { useState } from "react";
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "510px",
    },
    classList: {
      backgroundColor: theme.palette.background.default,
      borderRadius: 10,
      padding: 10,
      marginTop: 20,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    spacer: {
      flex: 1,
    },
    titleRow: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
    },
  })
);

export interface ScannerDialogProps {
  open: boolean;
  onClose: () => void;
  checkInRegistrationID: (registrationID: string) => void;
}

interface Request {
  id: string;
  name: string;
  company: string;
  title: string;
}

export const ScannerDialog: React.FC<ScannerDialogProps> = ({
  open,
  onClose,
  checkInRegistrationID,
}) => {
  const classes = useStyles();

  const [openRequests, setOpenRequests] = useState<Request[]>([]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpenRequests([]);
        onClose();
      }}
      maxWidth={"md"}
    >
      <DialogContent style={{ padding: 20 }}>
        <Box className={classes.titleRow}>
          <Typography variant="h6">Scan Event Badge</Typography>
          <Box style={{ flex: 1 }} />
          <IconButton
            size="small"
            onClick={() => {
              setOpenRequests([]);
              onClose();
            }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Box>
        <BarcodeScannerComponent
          width={500}
          height={400}
          onUpdate={(err, result) => {
            if (result) {
              const parsedResult = JSON.parse(result.getText());

              setOpenRequests([
                {
                  id: parsedResult["id"],
                  name: parsedResult["name"],
                  company: parsedResult["company"],
                  title: parsedResult["title"],
                },
              ]);
            }
          }}
        />
        {openRequests.length > 0 && (
          <Box className={classes.classList}>
            {openRequests.map((request, index) => (
              <Box
                className={classes.row}
                style={{ marginTop: index > 0 ? 10 : 0 }}
              >
                <Box>
                  <Typography
                    color="textPrimary"
                    style={{ fontWeight: "bold" }}
                  >
                    {request.name}
                  </Typography>
                  <Typography color="textSecondary">
                    {request.title}, {request.company}
                  </Typography>
                </Box>
                <Box className={classes.spacer} />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    checkInRegistrationID(request.id);
                    setOpenRequests(
                      openRequests.filter(
                        (openRequest) => openRequest.id !== request.id
                      )
                    );
                  }}
                  style={{ marginRight: 10 }}
                  endIcon={<CheckRoundedIcon />}
                >
                  Check In
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    event.stopPropagation();
                    setOpenRequests(
                      openRequests.filter(
                        (openRequest) => openRequest.id !== request.id
                      )
                    );
                  }}
                  endIcon={<ClearRoundedIcon />}
                >
                  Cancel
                </Button>
              </Box>
            ))}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
